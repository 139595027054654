<template>
  <div id="inventoryHistory-report-page" class="vs-row  w-full">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'InventoryLogReport'">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>

    <transition name="fade">
      <div class="vx-row">
        <div class="vx-col md:w-1/5 mt-20">
          <vx-card>

            <div class="vs-col " v-if="suppliersFilter">
              <vs-select
                autocomplete
                class="pr-4"
                :label="$t('Supplier')"
                v-model="selectedSupplierFilter"
                v-if="suppliersFilter.length > 0"
                @input="onSupplierFilterChanged"
              >
                <vs-select-item :key="index" :value="supplier.supplier_id + 'supplier_id'"
                                :text="supplier.supplier_name"
                                v-for="(supplier,index) in suppliersFilter"/>
              </vs-select>
            </div>

            <div class="py-2"></div>
            <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                       @click="clearFilterData">{{ $t('ClearAllFilters').toUpperCase() }}
            </vs-button>
          </vx-card>
        </div>
        <div class="vx-col md:w-4/5">

          <!--    Error List State-->
          <transition name="fade">
            <div class="py-6 flex w-full bg-img" v-if="errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
                <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                     class="mx-auto mb-4 max-w-full">
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
              </div>
            </div>
          </transition>
          <!--    Empty List State -->
          <transition name="fade">
            <div class="py-6 flex w-full bg-img"
                 v-if="inventoryHistory.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
                <!--              <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
                <!--                   class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified class="flex-1-1" v-show="inventoryHistory.length > 0 && !errorFetching"
                             ref="table"
                             :max-items="20"
                             :data="inventoryHistory"
          >
            <template slot="thead">
              <vs-th style="max-width: 72px;">{{ $t('ProductName') }}</vs-th>
              <vs-th style="max-width: 72px;">{{ $t('Supplier') }}</vs-th>
              <vs-th>{{ $t('Discount') }}</vs-th>
              <vs-th>{{ $t('ConfigAmount') }}</vs-th>
              <vs-th>{{ $t('TotalPins') }}</vs-th>
              <vs-th>{{ $t('ValidPins') }}</vs-th>
              <vs-th>{{ $t('InvalidPins') }}</vs-th>
              <vs-th>{{ $t('CreatedDate') }}</vs-th>
              <vs-th>{{ $t('Actions') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p class="product-name font-medium truncate">{{
                      tr.product_name | capitalize
                    }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.supplier }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.discount }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.configuration_value | germanNumberFormat }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.total_pins }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.successful_pins }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.unsuccessful_pins }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.created | dateFormat }}</p>
                </vs-td>
                <vs-td>
                  <p
                    v-if="tr.status == 0"
                    class="product-name font-medium truncate">Background task in progress</p>
                  <div
                    v-if="tr.unsuccessful_pins > 0"
                    @click="downloadFailedPins(tr.import_batch)"
                    class="flex flex-row space-x-2 justify-center items-center">
                    <vs-button class="ml-2" type="border" color="success">{{ $t('Download') }}
                    </vs-button>
                  </div>
<!--                  <div-->
<!--                    v-if="tr.status == 1"-->
<!--                    class="flex flex-row space-x-2 justify-center items-center">-->
<!--                    <vs-button class="ml-2" type="border" color="success"-->
<!--                               @click="approveInventoryUpload(tr)">{{ $t('Approve') }}-->
<!--                    </vs-button>-->
<!--                    <vs-button class="ml-2" type="border" color="danger"-->
<!--                               @click="cancelInventoryUpload(tr.import_batch)">{{ $t('Cancel') }}-->
<!--                    </vs-button>-->
<!--                  </div>-->
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage"
                                   v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  name: 'InventoryLogReport',
  components: {
    'v-select': vSelect,
  },
  data() {
    return {
      cPage: 1,
      errorFetching: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Inventory Log Report', i18n: 'InventoryLogReport', active: true},
      ],
      suppliersFilter: null,
      selectedSupplierFilter: null,
    }
  },
  computed: {
    inventoryHistory() {
      return this.$store.state.reports.inventoryHistory
    },
    vPage() {
      return this.$store.state.reports.vPage
    },
    vTotalPages() {
      return this.$store.state.reports.vTotalPages
    },
  },
  methods: {
    clearFilterData() {
      this.selectedSupplierFilter = null
      this.filterChanged()
    },
    getFilters() {
      const filter = {}
      if (this.selectedSupplierFilter) {
        filter.supplier_id = this.selectedSupplierFilter.replace('supplier_id', '')
      }
      return filter
    },
    filterChanged() {
      this.fetchInventoryHistory(this.getFilters())
    },
    onSupplierFilterChanged() {
      this.filterChanged()
    },
    listPageChanged(item) {
      this.fetchInventoryHistory(this.getFilters(), this.cPage - 1)
    },
    fetchInventoryHistory(payload = null, page = '0') {
      this.$vs.loading()
      return this.$store.dispatch('reports/fetchInventoryHistoryReports', {payload, page})
        .then((data) => {
          if (data.message_type === 1) {
            try {
              this.suppliersFilter = data.type_of_filter.suppliers
            } catch (e) {
              console.log(e)
            }
          }
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    cancelInventoryUpload(id) {

    },
    approveInventoryUpload(data) {
      console.log(data);
      this.$router.push({ name: 'inventory-approve', params: { inventoryData: JSON.stringify(data) }});

      // this.$store.dispatch('inventory/approveInventoryUpload', {
      //   import_batch: id,
      // }).catch((error) => {
      //   this.$vs.loading.close()
      //   this.$vs.notify({
      //     position: 'top-center',
      //     title: 'Error',
      //     text: error.response.data.message || error.message,
      //     iconPack: 'feather',
      //     icon: 'icon-alert-circle',
      //     color: 'danger',
      //   })
      //   return false
      // })
      //   .then((data) => {
      //     this.fetchInventoryHistory(this.getFilters(), this.cPage - 1)
      //   })
    },
    downloadFailedPins(id) {
      this.$vs.loading()
      this.$store.dispatch('inventory/downloadInvalidPins', {
        import_batch: id,
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      })
        .then((data) => {
          this.$vs.loading.close()
          const hiddenElement = document.createElement('a')
          hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
          hiddenElement.target = '_blank'
          hiddenElement.download = 'invalid_pins.csv';
          hiddenElement.click()
        })
    }

  },
  created() {
    this.$store.commit('reports/RESET')
    // this.fetchInventoryHistory()
  },
}
</script>

<style lang="scss">

.vs-sidebar.vs-sidebar-primary.vs-sidebar-position-right {
  max-width: 100vh !important;
}

.sidebar-product-image {
  /*max-width: 200px;*/
  max-height: 70px;
  object-fit: cover;
}


#inventoryHistory-report-page {
  .product-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }

  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .products-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }


}
</style>
